import Vue from 'vue'
import VueRouter from 'vue-router'

import base from '../utils/baseTools'

// import HomeView from '../views/HomeView.vue'
import Login from '../views/Login/login.vue'
import Project from '../views/Project/project.vue'
import Home from '../views/Project/Home/home.vue'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/',
    // name: 'project',
    component: Project,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
      },
      {
        path: 'user',
        name: 'user',
        component: () => import(/* webpackChunkName: "user" */ '../views/Project/User/user.vue'),
      },
      {
        path: 'help',
        name: 'help',
        component: () => import(/* webpackChunkName: "help" */ '../views/Project/Help/help.vue'),
      },
      {
        path: 'about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/About/about.vue'),
      },
      {
        path: 'detail/:taxonCode/:speciesCode',
        name: 'detail',
        component: () => import(/* webpackChunkName: "detail" */ '../views/Project/Detail/detail.vue'),
      },

    ]
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login
  },
  {
    path: '/test',
    name: 'test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "test" */ '../views/Test/test.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// router.beforeEach((to, from, next) => {
//   const userToken = base.storage.getStorage('userToken') || {}
//   if (to.meta.auth && !userToken.access) {
//     const url = `/login?back=${to.fullPath}`
//     router.replace(url)
//   } else if (to.name === 'login' && userToken.access) {
//     router.replace('/')
//   } else {
//     next()
//   }
// })
export default router
