import request from '../utils/request'
/* 所有类群 */
export function getAllGroup(data) {
  return request({
    url: 'api/taxons/',
    method: 'get',
    params: {
      ...data
    }
  })
}
/* 分期 */
export function getStages(data) {
  return request({
    url: 'api/stages/',
    method: 'get',
    params: {
      ...data
    }
  })
}

/* 模态 */
export function getModalities(data) {
  return request({
    url: 'api/modalities/',
    method: 'get',
    params: {
      ...data
    }
  })
}

/* 物种列表*/
export function querySpeciesList(data) {
  return request({
    url: 'api/species/',
    method: 'get',
    params: {
      ...data
    }
  })
}
/*物种详情*/
export function querySpeciesDetail(data) {
  return request({
    url: `api/species/${data}`,
    method: 'get'
  })
}

