import axios from 'axios'
import { Message } from 'element-ui'
import base from './baseTools'
import router from '../router'
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'https://dev-bioapi.gaptools.cn',
  baseURL: 'https://ismmi-api.hsientang.com/',
  // baseURL: process.env.NODE_ENV == "production"?"https://bioapi.gaptools.cn/":'https://dev-bioapi.gaptools.cn/',
  // baseURL: process.env.NODE_ENV == 'production' ? 'https://bioapi.gaptools.cn/' : 'https://dev-bioapi.gaptools.cn/',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})
// request interceptor
service.interceptors.request.use(
  config => {
    // console.log("config",config)
    const userToken = base.storage.getStorage('userToken') || {}
    if (userToken.access) {
      config.headers.Authorization = 'Bearer ' + userToken.access || ''
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    // if (res.status_code > 299 || res.status_code < 200) {
    //   if (res.status_code === 401 && res.detail != '账号或密码错误') {
    //     const userInfo = base.storage.getStorage('userInfo')
    //     const refresh = userInfo.refresh
    //     service({
    //       url: '/v1/token/refresh/',
    //       method: 'post',
    //       data: {
    //         refresh: refresh
    //       }
    //     }).then(res => {
    //       const data = res.data
    //       userInfo[refresh] = data[refresh]
    //       userInfo[access] = data[access]
    //       base.storage.setStorage('userInfo', userInfo)
    //     })
    //   } else {
    //     Message({
    //       message: res.detail || 'Error',
    //       type: 'error',
    //       duration: 5 * 1000
    //     })

    //     // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //     return Promise.reject(new Error(res.detail || 'Error'))
    //   }
    // } else {
    // console.log("res", res)
    // if (res && res.code !== '0') {
    //   Message({
    //     message: res.message,
    //     type: 'error',
    //     duration: 5 * 1000
    //   })
    // }
    // 用户未登录211000,登录过期214000
    if (res && res.code === '211000' || res.code === '214000') {
      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      })
      console.log('router', router)
      let backUrl = router.history._startLocation;
      const url = `/login?back=${backUrl}`
      router.replace(url)
    }
    else if (res && res.code != '0') {
      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return res
    // }
  },
  error => {
    if (error.response) {
      console.log("err1", error.response)
      // if (
      //   (error.response.stauts === 401 && error.response.statusText === 'Unauthorized') ||
      //   (error.response.data.status_code === 401 && error.response.data.detail == 'Given token not valid for any token type')) {
      //   const userInfo = base.storage.getStorage('userInfo')
      //   const refresh = userInfo.refresh
      //   service({
      //     url: '/v1/token/refresh/',
      //     method: 'post',
      //     data: {
      //       refresh: refresh
      //     }
      //   }).then(res => {
      //     const data = res
      //     userInfo.refresh = data.refresh
      //     userInfo.access = data.access
      //     base.storage.setStorage('userInfo', userInfo)
      //     window.location.reload()
      //   })
    } else {
      console.log('err' + error) // for debug
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }
  }

)

export default service
