<template>
  <div class="page project">
    <CHeader></CHeader>
    <div class="box">

      <div class="nav">
        <div class="nav-item" :class="{ 'active': $route.name === item.name }" v-for="(item, index) in navList"
          :key="index" @click="goPage(item)">
          <img :src="item.icon" v-show="$route.name !== item.name" />
          <img :src="item.iconActive" v-show="$route.name === item.name" />
          <div>{{ item.title }}</div>
        </div>
      </div>
      <div class="container">

        <router-view class="router-page" />
        <div class="bottom">
          <span class="copyright">版权所有（c）中国检验检疫科学研究院 备案号：浙ICP备2023012803号</span>
          <span class="link-con">
            <span>站点导航：</span>
            <span>人工智能算法训练迭代平台</span>
            <span>分布式快速鉴定平台</span>
            <span>入侵物种多模态鉴定客户端</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CHeader from '@/components/Header.vue'
export default {
  name: 'Project',
  components: {
    CHeader
  },
  data() {
    return {
      navList: [
        {
          title: '我的物种',
          icon: require('@/assets/images/icon_species.png'),
          iconActive: require('@/assets/images/icon_species_active.png'),
          name: 'home'
        },
        {
          title: '个人中心',
          icon: require('@/assets/images/icon_user.png'),
          iconActive: require('@/assets/images/icon_user_active.png'),
          name: 'user'
        },
        {
          title: '帮助文档',
          icon: require('@/assets/images/icon_help.png'),
          iconActive: require('@/assets/images/icon_help_active.png'),
          name: 'help'
        },
        {
          title: '关于我们',
          icon: require('@/assets/images/icon_about.png'),
          iconActive: require('@/assets/images/icon_about_active.png'),
          name: 'about'
        },
      ],
      activeNav: {
        title: '我的物种',
        icon: require('@/assets/images/icon_species.png'),
        iconActive: require('@/assets/images/icon_species_active.png'),
      },
    }
  },
  methods: {
    goPage(nav) {
      this.$router.push({ name: nav.name })
    }
  }
}
</script>
<style lang="less" scoped>
.project {
  .box {
    width: 100%;
    height: calc(100% - 64px);
    display: flex;

    .nav {
      width: 100px;
      height: 100%;
      overflow-y: auto;
      background: #EDF7F1;
      flex-shrink: 0;
      flex-grow: 0;

      .nav-item {
        width: 100%;
        height: 123px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.active {
          background: #FFFFFF;

          div {
            color: @themeColor;
          }

        }

        div {
          font-size: 16px;
          color: #A9A9A9;
        }

        .icon {
          width: 42px;
          height: 42px;
          margin-bottom: 10px;
        }

      }
    }

    .container {
      width: calc(100% - 100px);
      flex-shrink: 0;
      flex-grow: 0;
      background: #FAFAFB;

      .router-page {
        width: 100%;
        height: calc(100% - 40px);
      }

      .bottom {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #C3C8D8;
        padding: 0 40px;

        .link-con {
          span {
            font-size: 12px;
            color: @themeColorGray;
          }

          span:not(:first-child) {
            cursor: pointer;
          }

          span:nth-child(3) {
            margin-left: 30px;
            margin-right: 30px;
          }
        }
      }
    }

  }
}
</style>