function isJSON(jsonStr) {
  try {
    const aa = JSON.parse(jsonStr)
    if (typeof aa === 'object') {
      return true
    }
  } catch (e) { }
  return false
}
const _formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}
export default {
  storage: {
    // 读取cookie
    getCookie(c_name, cookie = '') {
      try {
        cookie = cookie || document.cookie
      } catch (e) { }
      if (cookie.length > 0) {
        var c_start = cookie.indexOf(c_name + '=')
        if (c_start != -1) {
          c_start = c_start + c_name.length + 1
          var c_end = cookie.indexOf(';', c_start)
          if (c_end == -1) c_end = cookie.length
          return unescape(cookie.substring(c_start, c_end))
        }
      }
      return ''
    },
    // 设置cookie
    setCookie(c_name, value, expiredays, path) {
      path = path || '/'
      var exdate = new Date()
      exdate.setDate(exdate.getDate() + expiredays)
      document.cookie =
        c_name +
        '=' +
        escape(value) +
        (expiredays == null ? '' : ';expires=' + exdate.toGMTString()) +
        ';path=' +
        path
    },
    removeCookie(name) {
      var exp = new Date()
      exp.setTime(exp.getTime() - 1)
      var cval = this.getCookie(name)
      if (cval != null) {
        document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString()
      }
    },
    // 读取会话
    getSession(name) {
      try {
        let value = sessionStorage.getItem(name)
        if (/^(\{|\[).*(\}|\])$/.test(value)) {
          value = JSON.parse(value)
        }
        return value
      } catch (e) { }
    },
    // 设置会话
    setSession(name, value) {
      try {
        if (typeof value === typeof {}) {
          value = JSON.stringify(value)
        }
        return sessionStorage.setItem(name, value)
      } catch (e) { }
    },
    setStorage(key, val) {
      try {
        if (typeof val === typeof {}) {
          val = JSON.stringify(val)
        }
        return localStorage.setItem(key, val)
      } catch (e) { }
    },
    getStorage(key) {
      try {
        const json = localStorage.getItem(key)
        if (isJSON(json)) {
          return JSON.parse(json)
        } else {
          return json
        }
      } catch (e) { }
    },
    removeStorage(key) {
      try {
        localStorage.removeItem(key)
      } catch (e) { }
    },
    removeSession(key) {
      try {
        sessionStorage.removeItem(key)
      } catch (e) { }
    }
  },
  format: {
    formatTime: (number, format) => {
      // 非空和类型校验
      if (!number || !/[0-9]+/.test(number)) {
        return number
      }
      const formateArr = ['Y', 'M', 'D', 'h', 'm', 's']
      const returnArr = []
      let date
      if (Number(number) != number) {
        date = new Date(number.replace(/-/g, '/'))
      } else {
        date = new Date(Number(number))
      }

      returnArr.push(date.getFullYear())
      returnArr.push(_formatNumber(date.getMonth() + 1))
      returnArr.push(_formatNumber(date.getDate()))

      returnArr.push(_formatNumber(date.getHours()))
      returnArr.push(_formatNumber(date.getMinutes()))
      returnArr.push(_formatNumber(date.getSeconds()))

      for (const i in returnArr) {
        format = format.replace(formateArr[i], returnArr[i])
      }
      return format
    },

    // mobile
    formatMobileMasking(num) {
      const n = num + '' || ''
      if (/^1[3-9][0-9]{9}$/.test(n)) {
        return n.slice(0, 3) + '****' + n.slice(-4)
      } else {
        return num
      }
    }

  },
  json: {
    isJSON
  },
  size: {
    // 格式化文件大小
    convertBytesToSize(bytes) {
      const thresh = 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }

      const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let u = -1;
      const r = 10 ** 2;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.abs(bytes) >= thresh && u < units.length - 1);

      return bytes.toFixed(1) + units[u];
    }
  }

}
