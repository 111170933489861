import request from '../utils/request'
/* 密码登录 */
export function toLoginPassword(params) {
  return request({
    url: 'api/login/password/',
    method: 'post',
    data: params
  })
}
/* 发送验证码 */
export function getSmsCode(params) {
  return request({
    url: 'api/sms-code/',
    method: 'post',
    data: params
  })
}
/* 验证码登录 */
export function toLoginCode(params) {
  return request({
    url: 'api/login/sms-code/',
    method: 'post',
    data: params
  })
}
/* 忘记密码 */
export function regetPassword(params) {
  return request({
    url: 'api/forget-password/',
    method: 'post',
    data: params
  })
}
/* 验证旧手机号 */
export function validatePhone(params) {
  return request({
    url: 'api/account/phone-number/validate/',
    method: 'post',
    data: params
  })
}
/* 设置新手机号 */
export function setNewPhone(params) {
  return request({
    url: 'api/account/phone-number/change/',
    method: 'post',
    data: params
  })
}
/* 用户信息 */
export function getUserInfo() {
  return request({
    url: 'api/account/profile/',
    method: 'get'
  })
}
/* 修改用户密码 */
export function changePassword(params) {
  return request({
    url: 'api/account/change-password/',
    method: 'post',
    data: params
  })
}
/* 修改用户基本信息-昵称-性别 */
export function changeInfo(params) {
  return request({
    url: 'api/account/profile/change/',
    method: 'post',
    data: params
  })
}
/* 修改用户头像 */
// export function modifyAvatar(params) {
//   return request({
//     url: 'api/account/change-avatar/',
//     method: 'post',
//     headers: { 'Content-Type': 'multipart/form-data' },
//     data: params
//   })
// }