<template>
  <div class="header">
    <img src="../assets/images/logo2.png">
    <div class="userinfo">
      <div class="user">
        <img :src="userInfo.profile.avatar" class="avatar">
        <span>{{ userInfo.username }}</span>
      </div>
      <img src="../assets/images/line.png">
      <div class="logout" @click="logout">
        <span>退出</span>
        <img src="../assets/images/logout.png">
      </div>
    </div>
  </div>
</template>
<script>
import {
  getUserInfo
} from "@/api/UserHttp.js"

export default {
  name: "CHeader",

  data() {
    return {
      userInfo: {
        profile: {}
      }
    }
  },
  mounted() {
    getUserInfo().then(res => {
      if (res && res.code === '0' && res.data) {
        let response = res.data;
        this.userInfo = response;
      }
    })
  },
  methods: {
    logout() {
      this.$base.storage.removeStorage('userToken');
      this.$router.replace("/login");
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  width: 100%;
  height: 64px;
  background: #252F3F;
  border: 1px solid #EBEBEB;
  line-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 11px;

  &>img {
    margin-left: 20px;
  }

  .userinfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // float: right;
    // height: 64px;
    height: 100%;
    margin-right: 20px;

    div {
      height: 100%;
      display: inline-block;
      vertical-align: middle;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      img {
        // vertical-align: middle;
      }

      span {
        font-size: 16px;
        color: #FFFFFF;
        // vertical-align: middle;
      }
    }

    .user {
      margin-right: 15px;

      img {
        // width: 17px;
        margin-right: 5px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      span {
        // margin-right: 15px;
      }
    }

    .logout {
      margin-left: 15px;

      span {
        margin-right: 5px;
      }
    }
  }
}
</style>