<template>
  <div class="input-item">
    <p>
      <span class="title">{{ title }}</span>
      <span class="error">{{ error }}</span>
    </p>
    <el-input v-model="msg" :placeholder="placeholder" :class="{ 'error-input': error }"
      :type="isPwd ? 'password' : 'text'" @input="updateMsg" :name="prop + new Date().getTime()"></el-input>
  </div>
</template>
<script>
export default {
  name: 'Input1',
  props: {
    prop: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    error: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "请输入"
    },
    isPwd: {
      type: Boolean,
      default: false
    },
  },
  components: {

  },
  data() {
    return {
      msg: ""
    }
  },
  beforeDestroy() {
    this.msg = "";
    // this.updateMsg();
  },
  methods: {
    updateMsg() {
      this.$emit("updateData", { prop: this.prop, data: this.msg });
    }
  }
}
</script>
<style lang="less" scoped>
.input-item {
  &>p {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .title {
      font-size: 16px;
      color: @themeColorGray2;
    }

    .error {
      font-size: 16px;
      color: @themeColorRed;
    }
  }

  /deep/ .el-input {
    width: 536px;
    height: 66px;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid @themeColorGray;

    &.error-input {
      border-color: @themeColorRed;
    }

    .el-input__inner {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}
</style>